import React from 'react'
import {Layout, Menu, Avatar, Dropdown, Button, AutoComplete} from 'antd'
import Flags from 'country-flag-icons/react/3x2'
import {DownOutlined} from '@ant-design/icons'
import {useHistory} from 'react-router-dom'
import debounceFn from 'debounce-fn'
import {useRecoilState} from 'recoil'
import {regionState} from '../state/Region'
import {useAuth0} from '@auth0/auth0-react'
import {Region} from '../model/Region'
import logo from '../assets/imgs/vahterus-logo.png'
import NavigationMenu from './NavigationMenu'
import SearchPropertiesForm from './SearchProperties/SearchPropertiesForm'
import {searchPropertiesFormVisibleState} from '../state/Atoms'

const {Header} = Layout

const GlobalHeader: React.FunctionComponent = () => {
	const history = useHistory()
	const {user, logout} = useAuth0()
	const [region, setRegion] = useRecoilState(regionState)

	const pathName = history.location.pathname
	const [showSearch, setShowSearch] = React.useState(
		pathName.includes('/dashboard'),
	)
	const [searchPropertiesFormVisible, setSearchPropertiesFormVisible] =
		useRecoilState(searchPropertiesFormVisibleState)

	React.useEffect(() => {
		const unregisterHistoryListener = history.listen(location => {
			const pathName = location.pathname

			setShowSearch(pathName.includes('/dashboard'))
		})

		return () => {
			unregisterHistoryListener()
		}
	}, [history])

	const handleMainMenuClick = ({key}) => {
		switch (key) {
			case 'logout': {
				localStorage.removeItem('VahterusRegion')
				logout()
				history.replace('/')
				break
			}
		}
	}

	const handleRegionMenu = ({key}) => {
		switch (parseInt(key)) {
			case Region.US: {
				setRegion(Region.US)
				localStorage.setItem('VahterusRegion', Region.US.toString())
				break
			}
			case Region.EU: {
				setRegion(Region.EU)
				localStorage.setItem('VahterusRegion', Region.EU.toString())
				break
			}
			case Region.CN: {
				setRegion(Region.CN)
				localStorage.setItem('VahterusRegion', Region.CN.toString())
				break
			}
		}
	}

	const renderRegion = () => {
		switch (region) {
			case Region.US: {
				return (
					<Flags.US
						title="United States"
						style={{width: '18px', marginRight: '5px'}}
					/>
				)
			}
			case Region.EU: {
				return (
					<Flags.EU
						title="Europe"
						style={{width: '18px', marginRight: '5px'}}
					/>
				)
			}
			case Region.CN: {
				return (
					<Flags.CN title="China" style={{width: '18px', marginRight: '5px'}} />
				)
			}
		}
	}

	const redirectSearchRoute = debounceFn(
		value => {
			const redirectRoute = `?keyword=${value}`

			history.push(redirectRoute)
		},
		{wait: 300},
	)

	const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		redirectSearchRoute(e)
	}

	const menu = (
		<Menu onClick={handleRegionMenu}>
			{user['http://vahterus/roles'].map(role => {
				switch (role) {
					case 'InternalChina':
						return (
							<Menu.Item key={Region.CN}>
								<div style={{display: 'flex', justifyContent: 'space-between'}}>
									<span>China</span>
									<Flags.CN
										title="China"
										style={{width: '30px', marginLeft: '10px'}}
									/>
								</div>
							</Menu.Item>
						)
					case 'InternalUS':
						return (
							<Menu.Item key={Region.US}>
								<div style={{display: 'flex', justifyContent: 'space-between'}}>
									<span>United States</span>
									<Flags.US
										title="United States"
										style={{width: '30px', marginLeft: '10px'}}
									/>
								</div>
							</Menu.Item>
						)
					case 'InternalEU':
						return (
							<Menu.Item key={Region.EU}>
								<div style={{display: 'flex', justifyContent: 'space-between'}}>
									<span>Europe</span>
									<Flags.EU
										title="Europe"
										style={{width: '30px', marginLeft: '10px'}}
									/>
								</div>
							</Menu.Item>
						)
					default:
						return null
				}
			})}
		</Menu>
	)

	return (
		<Header className="vahterus-header">
			<div className="vahterus-header-top-row">
				<div className="vahterus-logo">
					<img src={logo} height={16} alt="logo" />
				</div>
				<Menu
					onClick={handleMainMenuClick}
					mode="horizontal"
					className="vahterus-header-menu"
				>
					{showSearch ? (
						<Menu.Item
							key="search"
							className="vahterus-header-search-menu-item"
							style={{borderBottom: 'none'}}
						>
							<AutoComplete
								className="vahterus-header-search"
								onChange={handleSearch}
								placeholder="SEARCH"
								notFoundContent={
									user['http://vahterus/roles'].includes('Internal') ? (
										<a
											style={{textDecoration: 'none', fontSize: '12px'}}
											dangerouslySetInnerHTML={{__html: 'ADVANCED SEARCH'}}
											onClick={() => {
												if (
													user['http://vahterus/roles'].includes('Internal')
												) {
													setSearchPropertiesFormVisible(true)
												}
											}}
										></a>
									) : null
								}
							/>
						</Menu.Item>
					) : null}

					<Menu.Item key="logout" style={{borderBottom: 'none'}}>
						Logout
					</Menu.Item>
					<Menu.Item key="region" style={{borderBottom: 'none'}}>
						<Dropdown overlay={menu}>
							<Button type="text">
								{renderRegion()}
								Region <DownOutlined />
							</Button>
						</Dropdown>
						<Avatar size={40} src={user.picture} />
					</Menu.Item>
				</Menu>
			</div>
			{showSearch && searchPropertiesFormVisible ? (
				<SearchPropertiesForm />
			) : null}
			<NavigationMenu />
		</Header>
	)
}

export default GlobalHeader
