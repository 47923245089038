import React from 'react'
import {Route, Switch} from 'react-router-dom'
import Login from './pages/Login'
import {withAuthenticationRequired, useAuth0} from '@auth0/auth0-react'
import AuthenticatedApp from './AuthenticatedApp'
import DrawingPage from './pages/DrawingPage'
import {Alert} from 'antd'
import {sec} from './utils/security'
import './i18n'

const App: React.FunctionComponent = () => {
	const {getAccessTokenSilently, isAuthenticated} = useAuth0()
	sec.setAccessTokenSilently(getAccessTokenSilently)

	const renderUnAuthenticatedApp = () => {
		return (
			<Switch>
				<Route path="/playground" component={DrawingPage} />
				<Route path="/" component={Login} />
			</Switch>
		)
	}

	const ProtectedRoute = ({component, ...args}) => (
		<Route component={withAuthenticationRequired(component)} {...args} />
	)

	const renderEnvAlert = () => {
		const env = process.env.NODE_ENV

		if (env !== 'staging' && env !== 'development') {
			return null
		}

		return <Alert message={`${env} environment`} type="warning" banner={true} />
	}

	return (
		<div className="App">
			{renderEnvAlert()}
			{isAuthenticated ? (
				<ProtectedRoute component={AuthenticatedApp}></ProtectedRoute>
			) : (
				renderUnAuthenticatedApp()
			)}
		</div>
	)
}

export default App
